/**
 * ssh zlheng@192.168.1.4 -p 22
 * sudo docker cp ~/app/my-app/src/App.js 5cb2ad3ded1f:/usr/local/app/my-app/src/App.js
 * sudo docker cp ~/app/my-app/src/DataSource.js 5cb2ad3ded1f:/usr/local/app/my-app/src/DataSource.js
 *
 */

// "gray"; expire
// "silver";
// "maroon";
// "red"; restaurant
// "purple"; hotel/bar
// "fuchsia";
// "green"; activities
// "lime";
// "olive";
// "yellow";living / info
// "navy";
// "blue"; supermarket / mall
// "teal";
// "aqua";

// {
//   youtubeLink: "",
//   youtubeTitle:"",
//   description: "",
//   lat: ,
//   lng: ,
//   name: "",
//   googleMapLink: "",
//   googleMapEmbedLink: "",
//   chineseName: "",
//   color: "",
//   expire:undefined,
//   activityLink: "",
//   attention: ""
// },

export const MarkerData = () => {
  return {
    initConfig: {
      red: true,
      blue: false,
      green: false,
      purple: false,
      yellow: false,
      gray: false,
      expire: false,
      showMenu: false,
      dark: false,
    },
    data: [
      // {
      //   youtubeLink: "",
      //   youtubeTitle:"",
      //   description: "",
      //   lat: ,
      //   lng: ,
      //   name: "",
      //   googleMapLink: "",
      //   googleMapEmbedLink: "",
      //   chineseName: "",
      //   color: "",
      //   expire:undefined,
      //   activityLink: "",
      //   attention: ""
      // },
      {
        youtubeLink: "https://www.youtube.com/shorts/CwuHohXbVjQ",
        youtubeTitle:
          "这一份$33.8 有点贵 但真的好吃 #珀斯最好吃的日式咖喱 #珀斯美食",
        description: "珀斯最好吃的日式咖喱",
        lat: -31.95188,
        lng: 115.8535,
        name: "Rojiura Curry SAMURAI. Perth",
        googleMapLink: "https://goo.gl/maps/JQ9jAatFnfYsnDjM7",
        googleMapEmbedLink: "",
        chineseName: "",
        color: "red",
        expire: undefined,
        activityLink: "",
        attention: "",
      },
      {
        youtubeLink: "https://www.youtube.com/shorts/bDoYNolcKUI",
        youtubeTitle:
          "吃了个寂寞 #珀斯自助餐 #珀斯赌场自助餐 #115刀自助餐 #epicurean",
        description: "珀斯最贵的自助餐",
        lat: -31.95949,
        lng: 115.89355,
        name: "Epicurean",
        googleMapLink: "https://goo.gl/maps/BNyS8HoY5qdYxTdPA",
        googleMapEmbedLink: "",
        chineseName: "",
        color: "red",
        expire: undefined,
        activityLink: "",
        attention: "平日中午会便宜点，需要提前预定",
      },
      {
        youtubeLink: "https://www.youtube.com/shorts/TTGoqWmDOSM",
        youtubeTitle: "关了可惜啦 #DuckDuckBruce #珀斯早午餐",
        description: "Duck Duck Bruce 最后一天",
        lat: -32.05645,
        lng: 115.74654,
        name: "Duck Duck Bruce",
        googleMapLink: "https://goo.gl/maps/iCvpjbPVQhWi4DQG9",
        googleMapEmbedLink: "",
        chineseName: "",
        color: "red",
        expire: 1689609599000,
        activityLink: "",
        attention: "",
      },
      {
        youtubeLink: "https://www.youtube.com/shorts/2kPxbpIGxXA",
        youtubeTitle:
          "我说澳洲美食天堂，是，也不是，毕竟家家正宗，韩餐韩国人烧，日料日本人做",
        description: "珀斯超正宗韩餐",
        lat: -31.94788,
        lng: 115.85633,
        name: "Daily's 1988",
        googleMapLink: "https://goo.gl/maps/HmPJPwx4cAR4Pexz9",
        googleMapEmbedLink: "",
        chineseName: "",
        color: "red",
        expire: undefined,
        activityLink: "",
        attention: "",
      },
      {
        youtubeLink: "https://www.youtube.com/shorts/02KH1YUvO64",
        youtubeTitle: "新加坡酒店的自助餐",
        description: "跨年夜自助餐",
        lat: -31.95909,
        lng: 115.86832,
        name: "Pan Pacific Perth",
        googleMapLink: "https://goo.gl/maps/M39FwesWh6DeVgxG8",
        googleMapEmbedLink: "",
        chineseName: "",
        color: "red",
        expire: undefined,
        activityLink: "",
        attention:
          "跨年自助餐只在跨年夜有，他们家貌似还有圣诞夜自助餐，平时没有，先打电话确认",
      },
      {
        youtubeLink: "https://www.youtube.com/shorts/bHm2wb8mAJQ",
        youtubeTitle: "珀斯日落",
        description: "想到了周传雄的黄昏",
        lat: -31.94675,
        lng: 116.04961,
        name: "Gooseberry Hill Lookout",
        googleMapLink: "https://goo.gl/maps/yJFKPYsbPUDv14ZZA",
        googleMapEmbedLink: "",
        chineseName: "",
        color: "green",
        expire: undefined,
        activityLink: "",
        attention: "",
      },
      {
        youtubeLink: "https://www.youtube.com/shorts/yB9y6leBsKM",
        youtubeTitle: "坐船前吃个晕船药，能坐2楼坐2楼哦，这个岛真的很舒服",
        description: "Rottnest Island 珀斯老鼠岛",
        lat: -31.99574,
        lng: 115.54143,
        name: "Rottnest Island Visitor Centre",
        googleMapLink: "https://goo.gl/maps/vY44RYMdVDcGn4VV9",
        googleMapEmbedLink: "",
        chineseName: "",
        color: "green",
        expire: undefined,
        activityLink: "https://www.rottnestisland.com/",
        attention: "",
      },
      {
        youtubeLink: "https://www.youtube.com/shorts/23f1WqjOBTQ",
        youtubeTitle: "Nice",
        description: "珀斯新春文化节",
        lat: -31.94772,
        lng: 115.85725,
        name: "Northbridge Piazza",
        googleMapLink: "https://goo.gl/maps/oCBn5XsbFf3W4mSV8",
        googleMapEmbedLink: "",
        chineseName: "",
        color: "green",
        expire: 1675007999999,
        activityLink: "https://www.chungwah.org.au/",
        attention: "应该每年农历新年都会有哦",
      },
      {
        youtubeLink: "https://www.youtube.com/shorts/bOwtq5sx_-Y",
        youtubeTitle: "堵车的时候最方便",
        description: "珀斯水上公交",
        lat: -31.97133,
        lng: 115.8537,
        name: "Mends St Jetty",
        googleMapLink: "https://goo.gl/maps/HLHBfSSX3WVChQcU6",
        googleMapEmbedLink: "",
        chineseName: "",
        color: "green",
        expire: undefined,
        activityLink: "",
        attention: "",
      },
      {
        youtubeLink: "https://www.youtube.com/shorts/V6lpkVP8fZc",
        youtubeTitle: "哪天去等阿联酋A380",
        description: "珀斯看飞机",
        lat: -31.963888,
        lng: 115.957942,
        name: "王浩发现的观望点",
        googleMapLink: "https://goo.gl/maps/NNLnYXg3zWENs4Kb8",
        googleMapEmbedLink: "",
        chineseName: "",
        color: "green",
        expire: undefined,
        activityLink: "",
        attention: "",
      },
      {
        youtubeLink: "https://www.youtube.com/shorts/rcJFbLsdqv8",
        youtubeTitle: "原来自己就能修",
        description: "自己换车灯",
        lat: -31.90538,
        lng: 115.80861,
        name: "Supercheap Auto Osborne Park",
        googleMapLink: "https://goo.gl/maps/3sufh9v9BbETkXD29",
        googleMapEmbedLink: "",
        chineseName: "",
        color: "blue",
        expire: undefined,
        activityLink: "",
        attention: "",
      },
      {
        youtubeLink: "https://www.youtube.com/shorts/2IkVYj5RulQ",
        youtubeTitle: "我拍不全 店太多了",
        description: "悉尼文和友？",
        lat: -33.87787,
        lng: 151.1033,
        name: "Burwood Chinatown",
        googleMapLink: "https://goo.gl/maps/QyvBti1otm4jvVbA7",
        googleMapEmbedLink: "",
        chineseName: "",
        color: "red",
        expire: undefined,
        activityLink: "",
        attention: "",
      },
      {
        youtubeLink: "https://www.youtube.com/shorts/_vkwhsnBw38",
        youtubeTitle: "户外也真是太棒了",
        description: "珀斯的海边",
        lat: -32.05825,
        lng: 115.74171,
        name: "Bathers Beach",
        googleMapLink: "https://goo.gl/maps/45rXr2wjZGcnk9CV9",
        googleMapEmbedLink: "",
        chineseName: "",
        color: "green",
        expire: undefined,
        activityLink: "",
        attention: "注意防晒",
      },
      {
        youtubeLink: "https://www.youtube.com/shorts/XfNkJ4j4ma4",
        youtubeTitle: "快快把医保用起来！",
        description: "如何配处方药",
        lat: -31.95247,
        lng: 115.85392,
        name: "Chemist Warehouse Hay Street Perth",
        googleMapLink: "https://goo.gl/maps/kQJiez1jMD77YpYj6",
        googleMapEmbedLink: "",
        chineseName: "",
        color: "blue",
        expire: undefined,
        activityLink: "",
        attention: "",
      },
      {
        youtubeLink: "https://www.youtube.com/shorts/FvDl5vjA4fE",
        youtubeTitle: "见过一次就得了",
        description: "珀斯高档酒店",
        lat: -31.9582,
        lng: 115.85841,
        name: "The Ritz-Carlton, Perth",
        googleMapLink: "https://goo.gl/maps/1RwHgebeVvL28BLp8",
        googleMapEmbedLink: "",
        chineseName: "",
        color: "purple",
        expire: undefined,
        activityLink: "",
        attention: "",
      },
      {
        youtubeLink: "https://www.youtube.com/shorts/tZYAPcaGN5M",
        youtubeTitle: "如果在野外 我应该是放弃的那个",
        description: "珀斯室内攀岩",
        lat: -31.86562,
        lng: 115.88212,
        name: "Summit Climbing - Malaga",
        googleMapLink: "https://goo.gl/maps/HPEqV5oSvNHbsZvk6",
        googleMapEmbedLink: "",
        chineseName: "",
        color: "green",
        expire: undefined,
        activityLink: "",
        attention: "",
      },
      {
        youtubeLink: "https://www.youtube.com/shorts/1pbxVgwxTyg",
        youtubeTitle: "好吃不贵",
        description: "珀斯早午餐",
        lat: -31.94832,
        lng: 115.84155,
        name: "Atlas Food + Coffee",
        googleMapLink: "https://goo.gl/maps/xkpqYrQbS2ALnAjr9",
        googleMapEmbedLink: "",
        chineseName: "",
        color: "red",
        expire: undefined,
        activityLink: "",
        attention: "",
      },
      {
        youtubeLink: "https://www.youtube.com/shorts/CZJBnKs0RjY",
        youtubeTitle: "用完炉子要洗干净，垃圾要丢垃圾桶",
        description: "周末野外烧烤",
        lat: -31.88336,
        lng: 116.09226,
        name: "John Forrest National Park",
        googleMapLink: "https://goo.gl/maps/TsfnZucrDiZYfMbL6",
        googleMapEmbedLink: "",
        chineseName: "",
        color: "green",
        expire: undefined,
        activityLink: "",
        attention: "不能喂小动物哦",
      },
      {
        youtubeLink: "https://www.youtube.com/shorts/WHvr0iT7D0M",
        youtubeTitle: "我好奇里面是啥，是船吗",
        description: "珀斯蓝色船屋",
        lat: -31.97352,
        lng: 115.82657,
        name: "Blue Boat House",
        googleMapLink: "https://goo.gl/maps/aiKU1TW2qA919PyC9",
        googleMapEmbedLink: "",
        chineseName: "",
        color: "green",
        expire: undefined,
        activityLink: "",
        attention: "",
      },
      {
        youtubeLink: "https://www.youtube.com/shorts/TFXmQov0Jy4",
        youtubeTitle: "city 也有类似的，只想吃选 city 的店就好",
        description: "珀斯龙虾工厂",
        lat: -30.49363,
        lng: 115.06887,
        name: "Lobster Shack Cervantes WA",
        googleMapLink: "https://goo.gl/maps/4p18faBEjTJeEqbf8",
        googleMapEmbedLink: "",
        chineseName: "",
        color: "red",
        expire: undefined,
        activityLink: "",
        attention: "",
      },
      {
        youtubeLink: "https://www.youtube.com/shorts/us1v1AA_-ok",
        youtubeTitle: "好玩性：一般",
        description: "珀斯尖峰石阵",
        lat: -30.59146,
        lng: 115.15814,
        name: "The Pinnacles Desert",
        googleMapLink: "https://goo.gl/maps/vUd48QUFGvUnZqL27",
        googleMapEmbedLink: "",
        chineseName: "",
        color: "green",
        expire: undefined,
        activityLink: "",
        attention: "",
      },
      {
        youtubeLink: "https://www.youtube.com/shorts/fvwRmWEm79U",
        youtubeTitle: "太幸福了 能吃到墨西哥菜",
        description: "墨西哥菜好吃哦",
        lat: -31.98196,
        lng: 115.77985,
        name: "Pollito Chipotle Grill",
        googleMapLink: "https://goo.gl/maps/D8wkZUFpZquA9q2F9",
        googleMapEmbedLink: "",
        chineseName: "",
        color: "red",
        expire: undefined,
        activityLink: "",
        attention: "",
      },
      {
        youtubeLink: "https://www.youtube.com/shorts/VkIkUlD8k2w",
        youtubeTitle: "真的应该玩共享位置捉迷藏",
        description: "公司团建",
        lat: -31.95151,
        lng: 115.85415,
        name: "Flight Club Perth",
        googleMapLink: "https://goo.gl/maps/kpPx39XaWx5h1RVaA",
        googleMapEmbedLink: "",
        chineseName: "",
        color: "purple",
        expire: undefined,
        activityLink: "",
        attention: "",
      },
      {
        youtubeLink: "https://www.youtube.com/shorts/7CC1IPLMP84",
        youtubeTitle: "真是太棒了",
        description: "抹茶冰激凌",
        lat: -31.95875,
        lng: 115.85756,
        name: "Baskin-Robbins Elizabeth Quay",
        googleMapLink: "https://goo.gl/maps/BNVbfcbHr3G6UxVr5",
        googleMapEmbedLink: "",
        chineseName: "",
        color: "red",
        expire: undefined,
        activityLink: "",
        attention: "",
      },
      {
        youtubeLink: "https://www.youtube.com/shorts/PLWt7m2KCAk",
        youtubeTitle: "有没有唱中文歌的吧",
        description: "珀斯彩虹吧",
        lat: -31.95035,
        lng: 115.86246,
        name: "The Court",
        googleMapLink: "https://goo.gl/maps/m3ziBs68qJqdts3b6",
        googleMapEmbedLink: "",
        chineseName: "",
        color: "purple",
        expire: undefined,
        activityLink: "",
        attention: "",
      },
      {
        youtubeLink: "https://www.youtube.com/shorts/JgSdYTk_xNI",
        youtubeTitle: "太...累了",
        description: "珀斯滑沙",
        lat: -31.00206,
        lng: 115.33071,
        name: "Lancelin Sand Dunes",
        googleMapLink: "https://goo.gl/maps/tAMZ9HBkgtapMNj59",
        googleMapEmbedLink: "",
        chineseName: "",
        color: "green",
        expire: undefined,
        activityLink: "",
        attention: "",
      },
      {
        youtubeLink: "https://www.youtube.com/shorts/bApzG3mnhkw",
        youtubeTitle: "不好吃我不会发的哦",
        description: "好吃的马来炒饭",
        lat: -31.97275,
        lng: 115.85275,
        name: "Satay on Charcoal",
        googleMapLink: "https://goo.gl/maps/zxxqny23dSfY7hacA",
        googleMapEmbedLink: "",
        chineseName: "",
        color: "red",
        expire: undefined,
        activityLink: "",
        attention: "",
      },
      {
        youtubeLink: "https://www.youtube.com/shorts/3EqDnE0sJs8",
        youtubeTitle: "处方药的话需要有处方，再去里面柜台才行哦",
        description: "珀斯如何4 药房买药",
        lat: -31.94532,
        lng: 115.86148,
        name: "Chemist Warehouse Northbridge",
        googleMapLink: "https://goo.gl/maps/Wje6kkSLiuXazwuB8",
        googleMapEmbedLink: "",
        chineseName: "",
        color: "blue",
        expire: undefined,
        activityLink: "",
        attention: "",
      },
      {
        youtubeLink: "https://www.youtube.com/watch?v=dQbpSACnrvc",
        youtubeTitle:
          "以后大家看到超市门口的华人报纸可以多多关注，上面有活动信息滴",
        description: "珀斯华人美食大比拼",
        lat: -31.88601,
        lng: 115.82314,
        name: "Chung Wah Cultural Centre",
        googleMapLink: "https://goo.gl/maps/LqhRLa9Yg5c9gtw58",
        googleMapEmbedLink: "",
        chineseName: "",
        color: "green",
        expire: 1679846399999,
        activityLink: "",
        attention: "关注 珀斯邮报 获得相关活动信息",
      },
      {
        youtubeLink: "https://www.youtube.com/watch?v=0sIslYlsh8Y",
        youtubeTitle: "店名叫 Petition，去年拿了奖滴",
        description: "珀斯现代澳大利亚菜",
        lat: -31.95547,
        lng: 115.86012,
        name: "Petition",
        googleMapLink: "https://goo.gl/maps/gCB9nKf7sU3JdevY8",
        googleMapEmbedLink: "",
        chineseName: "",
        color: "red",
        expire: undefined,
        activityLink: "",
        attention: "",
      },
      {
        youtubeLink: "https://www.youtube.com/watch?v=HK2wkzBrxMM",
        youtubeTitle: "好吃哦",
        description: "悉尼战斧牛排",
        lat: -33.85665,
        lng: 151.2091,
        name: "6HEAD",
        googleMapLink: "https://goo.gl/maps/xLvcJ3SvuM4ALqk88",
        googleMapEmbedLink: "",
        chineseName: "",
        color: "red",
        expire: undefined,
        activityLink: "",
        attention: "",
      },
      {
        youtubeLink: "https://www.youtube.com/watch?v=1LYjQGi9yws",
        youtubeTitle: "以前的物价是不是回不去了",
        description: "谁发明的Surcharge",
        lat: -37.82482,
        lng: 144.95724,
        name: "Kitchen Workshop",
        googleMapLink: "https://goo.gl/maps/2u47AYvZoyXbvf9n6",
        googleMapEmbedLink: "",
        chineseName: "",
        color: "red",
        expire: undefined,
        activityLink: "",
        attention: "",
      },
      {
        youtubeLink: "https://www.youtube.com/watch?v=bqGy4kmq0Js",
        youtubeTitle: "希望珀斯人更多起来，国际餐饮更多来点儿",
        description: "澳洲的海底捞",
        lat: -37.81219,
        lng: 144.96377,
        name: "Haidilao Hotpot Emporium（海底捞火锅City店）",
        googleMapLink: "https://goo.gl/maps/h7Ja49pj4eeEQPcXA",
        googleMapEmbedLink: "",
        chineseName: "",
        color: "red",
        expire: undefined,
        activityLink: "",
        attention: "",
      },
      {
        youtubeLink: "https://www.youtube.com/watch?v=6dogW70Ymho",
        youtubeTitle: "生活成本并不高",
        description: "澳洲国民商场Kmart",
        lat: -31.985,
        lng: 115.90189,
        name: "Kmart East Victoria Park",
        googleMapLink: "https://goo.gl/maps/faLMiQ8PLvQHp7N57",
        googleMapEmbedLink: "",
        chineseName: "",
        color: "blue",
        expire: undefined,
        activityLink: "",
        attention: "",
      },
      {
        youtubeLink: "https://www.youtube.com/watch?v=SS-gN1JEknI",
        youtubeTitle: "这家店强烈推荐心脏好的朋友们去",
        description: "澳洲服务第一的餐厅",
        lat: -31.94743,
        lng: 115.82645,
        name: "Karen's Diner",
        googleMapLink: "https://goo.gl/maps/3gNXN5F28QC85ZrV6",
        googleMapEmbedLink: "",
        chineseName: "",
        color: "red",
        expire: 1,
        activityLink: "",
        attention: "永久关店",
      },
      {
        youtubeLink: "https://www.youtube.com/watch?v=R3h0Rvu6ffI",
        youtubeTitle: "下次，5点开门前就到",
        description: "珀斯最棒的意大利面",
        lat: -31.97778,
        lng: 115.86888,
        name: "Ciao Italia",
        googleMapLink: "https://goo.gl/maps/LJyphGwCntDsbosR6",
        googleMapEmbedLink: "",
        chineseName: "",
        color: "red",
        expire: undefined,
        activityLink: "",
        attention: "",
      },
      {
        youtubeLink: "https://www.youtube.com/watch?v=mBaehZLV7tk",
        youtubeTitle: "Surprise",
        description: "珀斯食阁",
        lat: -32.04559,
        lng: 115.95271,
        name: "Spencer Village Food Court",
        googleMapLink: "https://goo.gl/maps/XjUZAtAKn1RQiBc79",
        googleMapEmbedLink: "",
        chineseName: "",
        color: "red",
        expire: undefined,
        activityLink: "",
        attention: "",
      },
      {
        youtubeLink: "https://www.youtube.com/watch?v=6HLH7k0SWnw",
        youtubeTitle: "云逛珀斯商场",
        description: "珀斯最大的商场",
        lat: -31.87735,
        lng: 115.77817,
        name: "Karrinyup Shopping Centre",
        googleMapLink: "https://goo.gl/maps/Z7iwgxLKSaqo56jS8",
        googleMapEmbedLink: "",
        chineseName: "",
        color: "blue",
        expire: undefined,
        activityLink: "",
        attention: "",
      },
      {
        youtubeLink: "https://www.youtube.com/watch?v=ErWcMHbjpdc",
        youtubeTitle: "大家常去ALDI买的啥",
        description: "ALDI超市",
        lat: -31.84515,
        lng: 115.81322,
        name: "ALDI",
        googleMapLink: "https://goo.gl/maps/KtFiyRzUDBCH5TrC7",
        googleMapEmbedLink: "",
        chineseName: "",
        color: "blue",
        expire: undefined,
        activityLink: "",
        attention: "",
      },
      {
        youtubeLink: "https://www.youtube.com/watch?v=ruWX136eOGw",
        youtubeTitle: "云逛奢侈品商店",
        description: "Officeworks",
        lat: -31.94341,
        lng: 115.92481,
        name: "Officeworks Belmont",
        googleMapLink: "https://goo.gl/maps/WD2BZuRHqMNtZ7G98",
        googleMapEmbedLink: "",
        chineseName: "",
        color: "blue",
        expire: undefined,
        activityLink: "",
        attention: "",
      },
      {
        youtubeLink: "https://www.youtube.com/watch?v=_nqvcO7_9gA",
        youtubeTitle:
          "我啥时候骗过人哦 超好吃就是超好吃 #珀斯日料 #澳洲美食 #日本美食",
        description: "超好吃的三文鱼头火锅",
        lat: -31.9463,
        lng: 115.82512,
        name: "ZEN Japanese Restaurant",
        googleMapLink: "https://goo.gl/maps/xhkzgUeG4S393Q2h6",
        googleMapEmbedLink: "",
        chineseName: "",
        color: "red",
        expire: undefined,
        activityLink: "",
        attention: "三文鱼头火锅可以打电话提前订",
      },
      {
        youtubeLink: "https://www.youtube.com/watch?v=jj2QpxOlwyA",
        youtubeTitle: "太爽啦！",
        description: "珀斯台湾菜",
        lat: -31.97422,
        lng: 115.88979,
        name: "Cargill Cafe",
        googleMapLink: "https://goo.gl/maps/gm2mFPHxM87PHJAc6",
        googleMapEmbedLink: "",
        chineseName: "",
        color: "red",
        expire: undefined,
        activityLink: "",
        attention: "",
      },
      {
        youtubeLink: "https://www.youtube.com/shorts/lRyJA74-_Nc",
        youtubeTitle:
          "只要有航班，敲章小房间就是开门的，不用担心 #澳洲离境退税 #澳洲旅行 #澳洲手机卡买Telstra #澳洲TRS #TRS",
        description: "澳洲离境退税避坑",
        lat: -31.94121,
        lng: 115.97518,
        name: "ABF Client Services",
        googleMapLink: "https://goo.gl/maps/bwt3xuPLsH4wvQCj6",
        googleMapEmbedLink: "",
        chineseName: "",
        color: "yellow",
        expire: undefined,
        activityLink: "",
        attention: "有航班就开门，不用担心",
      },

      {
        youtubeLink: "https://www.youtube.com/shorts/DWR5855zbSs",
        youtubeTitle: "Costco 东西真不贵哦 #澳洲 #costco #生活开销",
        description: "澳洲$200都买了啥",
        lat: -31.94102,
        lng: 115.95123,
        name: "Costco Wholesale Perth Airport",
        googleMapLink: "https://goo.gl/maps/J99TBqvJoZpJUuq87",
        googleMapEmbedLink: "",
        chineseName: "",
        color: "blue",
        expire: undefined,
        activityLink: "",
        attention: "需要有会员卡才能入内",
      },
      {
        youtubeLink: "https://www.youtube.com/shorts/GVJHowz_kh8",
        youtubeTitle: "5大块肉$30",
        description: "珀斯韩国烤肉自己买",
        lat: -32.04518,
        lng: 115.81214,
        name: "Perth Kimchi",
        googleMapLink: "https://goo.gl/maps/476qfuZBMfcQFRos5",
        googleMapEmbedLink: "",
        chineseName: "",
        color: "blue",
        expire: undefined,
        activityLink: "",
        attention: "",
      },
      {
        youtubeLink: "https://www.youtube.com/shorts/6hFEY6pZpTE",
        youtubeTitle: "我天天就google buffet",
        description: "新年自助餐",
        lat: -31.93825,
        lng: 115.93226,
        name: "Eyre Restaurant",
        googleMapLink: "https://goo.gl/maps/ayPUoYTb44GonqfP8",
        googleMapEmbedLink: "",
        chineseName: "",
        color: "red",
        expire: undefined,
        activityLink: "",
        attention:
          "新年自助餐只在农历新年有，现在貌似每月最后一个周六有自助，平时没有，先打电话确认",
      },
      {
        youtubeLink: "https://www.youtube.com/shorts/LmROW8jlEmA",
        youtubeTitle: "好吃",
        description: "珀斯红油抄手",
        lat: -31.95208,
        lng: 115.85809,
        name: "BamBamBoo",
        googleMapLink: "https://goo.gl/maps/NaMBF7YL4UrkmPS78",
        googleMapEmbedLink: "",
        chineseName: "",
        color: "red",
        expire: undefined,
        activityLink: "",
        attention: "",
      },
      {
        youtubeLink: "https://www.youtube.com/shorts/75JYwEbfM_A",
        youtubeTitle: "爽了",
        description: "珀斯海鲜店",
        lat: -31.93841,
        lng: 115.841,
        name: "Kailis Bros Leederville",
        googleMapLink: "https://goo.gl/maps/Et4uE63a69TkMqA17",
        googleMapEmbedLink: "",
        chineseName: "",
        color: "blue",
        expire: undefined,
        activityLink: "",
        attention: "",
      },
      {
        youtubeLink: "https://www.youtube.com/shorts/llgQt3ugrSY",
        youtubeTitle: "我要把各个国家的菜都吃吃",
        description: "珀斯尼泊尔菜",
        lat: -31.97294,
        lng: 115.896,
        name: "MoMo Planet",
        googleMapLink: "https://goo.gl/maps/caUqgR2aHuR6QDLm6",
        googleMapEmbedLink: "",
        chineseName: "",
        color: "red",
        expire: undefined,
        activityLink: "",
        attention: "",
      },
      {
        youtubeLink: "https://www.youtube.com/shorts/AkvKwntgyEc",
        youtubeTitle: "新年快乐 希望新的一年再张涨8000粉",
        description: "珀斯年夜饭 2023",
        lat: -31.94717,
        lng: 115.85827,
        name: "Hong Kong BBQ House",
        googleMapLink: "https://goo.gl/maps/oPbKW7LYbo8JER5J6",
        googleMapEmbedLink: "",
        chineseName: "",
        color: "red",
        expire: undefined,
        activityLink: "",
        attention: "好像只在农历新年那阵子有，需要打电话预定",
      },
      {
        youtubeLink: "https://www.youtube.com/shorts/w5bXx9L2kt0",
        youtubeTitle: "我想天天去海边 浪",
        description: "Rottnest岸边设施",
        lat: -31.98958,
        lng: 115.53541,
        name: "The Basin",
        googleMapLink: "https://goo.gl/maps/8miV6DMYW3fzrDCv7",
        googleMapEmbedLink: "",
        chineseName: "",
        color: "yellow",
        expire: undefined,
        activityLink: "",
        attention: "",
      },
      {
        youtubeLink: "https://www.youtube.com/shorts/9D3-qk0qmWo",
        youtubeTitle: "我慢慢探索，各个国家有各个国家的菜",
        description: "珀斯柬埔寨菜",
        lat: -31.95272,
        lng: 115.87702,
        name: "Royal Cambodia Restaurant",
        googleMapLink: "https://goo.gl/maps/NGLfRNssavfNsuBs5",
        googleMapEmbedLink: "",
        chineseName: "",
        color: "red",
        expire: undefined,
        activityLink: "",
        attention: "",
      },
      {
        youtubeLink: "https://www.youtube.com/shorts/DydfVR0pCdI",
        youtubeTitle: "珀斯也是热闹的哦 那种惬意的热闹",
        description: "珀斯周五晚集市",
        lat: -31.9526,
        lng: 115.85922,
        name: "Twilight Hawkers Market",
        googleMapLink: "https://goo.gl/maps/N6sN7ohBer8Ayoot6",
        googleMapEmbedLink: "",
        chineseName: "",
        color: "green",
        expire: 1680278399999,
        activityLink: "",
        attention: "虽然这个集市结束了，但是不定期会有其他集市",
      },
      {
        youtubeLink: "https://www.youtube.com/shorts/_1yKOxR-Gh8",
        youtubeTitle:
          "逛逛心情好好哦 周末要不要去逛逛 #珀斯欧洲小镇 #珀斯美景 #珀斯冬日",
        description: "珀斯冬日漫游",
        lat: -31.89553,
        lng: 115.98062,
        name: "Guildford Grammar School",
        googleMapLink: "https://goo.gl/maps/3pQAeumKA1TkqHQd8",
        googleMapEmbedLink: "",
        chineseName: "",
        color: "green",
        expire: undefined,
        activityLink: "",
        attention: "",
      },
      {
        youtubeLink: "https://www.youtube.com/watch?v=N1_NCmV0TOY",
        youtubeTitle:
          "东西慢慢买 结账慢慢结 这边慢悠悠 一点不着急 欢迎补充购物小 Tips",
        description: "如何珀斯2 超市购物",
        lat: -31.9427,
        lng: 115.86811,
        name: "Woolworths Highgate",
        googleMapLink: "https://goo.gl/maps/Wr9hUgu7T2SfwFLo6",
        googleMapEmbedLink: "",
        chineseName: "",
        color: "blue",
        expire: undefined,
        activityLink: "",
        attention: "",
      },

      {
        youtubeLink: "https://www.youtube.com/watch?v=JxrVfw3s01Q",
        youtubeTitle: "太爽了 大家方便的话穿高帮鞋或高帮胶鞋，以免被夹",
        description: "珀斯抓螃蟹",
        lat: -32.75474,
        lng: 115.69532,
        name: "Island Point Reserve Public Toilets",
        googleMapLink: "https://goo.gl/maps/ajVXdsVD2Gz8LdQN8",
        googleMapEmbedLink: "",
        chineseName: "",
        color: "green",
        expire: undefined,
        activityLink: "",
        attention: "",
      },
      {
        youtubeLink: "https://www.youtube.com/shorts/PtihzLsLGBI",
        youtubeTitle:
          "建议去逛逛 提前网上买票 当天买票难 #珀斯灯光秀 #珀斯好玩",
        description: "超漂亮的珀斯灯光秀",
        lat: -31.96216,
        lng: 115.84106,
        name: "Kings Park Lightscape",
        googleMapLink: "https://goo.gl/maps/LHnXitHg9ap7CcL7A",
        chineseName: "",
        color: "green",
        expire: 1690732799999,
        activityLink:
          "https://www.bgpa.wa.gov.au/kings-park/events/kings-park-lightscape",
      },
      {
        youtubeLink: "https://www.youtube.com/shorts/Pja2iKCjLTw",
        youtubeTitle:
          "大家给我推荐推荐 火锅店！#珀斯火锅#四川火锅 #重庆火锅 #珀斯美食 #火锅烧烤",
        description: "珀斯地道火锅",
        lat: -32.02628,
        lng: 115.94431,
        name: "大富贵火锅（BIG RICH HOTPOT）",
        googleMapLink: "https://goo.gl/maps/p4khSrYaXsdzcGWM6",
        googleMapEmbedLink:
          "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3382.590764734215!2d115.94163501982116!3d-32.026197328242525!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2a32bd0fae49c813%3A0x10c2bebf1a31c87c!2z5aSn5a-M6LS154Gr6ZSF77yIQklHIFJJQ0ggSE9UUE9U77yJ!5e0!3m2!1sen!2sau!4v1688733899879!5m2!1sen!2sau",
        chineseName: "",
        color: "red",
        expire: undefined,
      },
      {
        youtubeLink: "https://www.youtube.com/shorts/aTXZ6UfyHP4",
        youtubeTitle: "真的不错哦",
        description: "悉尼最好喝的奶茶？",
        lat: -33.87468,
        lng: 151.20714,
        name: "Tea Shop Express",
        googleMapLink: "https://goo.gl/maps/rQDAivbcKuZWsYWN8",
        googleMapEmbedLink:
          "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2326.167309834469!2d151.20529987140887!3d-33.87472179697039!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b12ae3c492ede15%3A0xddbf7d9831348974!2sTea%20Shop%20Express!5e0!3m2!1sen!2sau!4v1688733003813!5m2!1sen!2sau",
        chineseName: "",
        color: "red",
        expire: undefined,
      },
      {
        youtubeLink: "https://www.youtube.com/shorts/1HxLdASRgjw",
        youtubeTitle:
          "哪里可以吃到大饼夹油条🤤 #悉尼美食 #天津煎饼 #天津包子 #vividsydney #夜宵",
        description: "包子",
        lat: -33.87907,
        lng: 151.20355,
        name: "Tian Jin Bao Zi Pu 天津包子铺",
        googleMapLink: "https://goo.gl/maps/n1DN6JXiKyBxsQZa6",
        chineseName: "",
        color: "red",
        expire: undefined,
      },
      {
        youtubeLink: "https://www.youtube.com/shorts/bVAij9EjbWY",
        youtubeTitle:
          "我貌似自己也能做 #FIVEGUYS #悉尼汉堡 #刘嘉玲汉堡 #奶昔 #澳洲美食",
        description: "最好吃的汉堡？",
        lat: -33.86934,
        lng: 151.20669,
        name: "Five Guys",
        googleMapLink: "https://goo.gl/maps/ckRR9Vjb4H6HNFdo6",
        chineseName: "",
        color: "red",
        expire: undefined,
      },
      {
        youtubeLink: "https://www.youtube.com/shorts/lDvVU8tfUZU",
        youtubeTitle:
          "真的不错 还没涨价 大盘鸡$39 #珀斯美食 #大盘鸡 #羊肉串 #西红柿鸡蛋拌面 #良心商家",
        description: "珀斯最好吃的大盘鸡",
        lat: -31.97442,
        lng: 115.8977,
        name: "Silk Road Uyghur Cuisine",
        googleMapLink: "https://goo.gl/maps/jTVwN8A19648bD5EA",
        chineseName: "",
        color: "red",
        expire: undefined,
      },
      {
        youtubeLink: "https://www.youtube.com/shorts/LFug7XpF0w0",
        youtubeTitle:
          "吃爽了，对了，珀斯的铁锅炖在哪？#东北铁锅炖 #东北菜 #悉尼吃喝玩乐日记 #澳洲美食 #悉尼美食",
        description: "东北铁锅炖",
        lat: -33.79539,
        lng: 151.1821,
        name: "Jilin Family Chatswood",
        googleMapLink: "https://goo.gl/maps/aJbf2F1FQ1LkGodZ6",
        chineseName: "",
        color: "red",
        expire: undefined,
      },
      {
        youtubeLink: "https://www.youtube.com/shorts/0GAWNOgufqc",
        youtubeTitle:
          "你们说的我都会做哦 #珀斯湘菜 #七里湘 #UWA学联贴纸9折 #珀斯中餐 #北桥美食",
        description: "珀斯七里湘",
        lat: -31.94732,
        lng: 115.86304,
        name: "Chilli Panda",
        googleMapLink: "https://goo.gl/maps/rjMwHHJqioXSGYZk9",
        chineseName: "",
        color: "red",
        expire: undefined,
      },
      {
        youtubeLink: "https://www.youtube.com/shorts/SntXbdMWHLE",
        youtubeTitle: "我还是希望有自己的厨房和洗衣间",
        description: "珀斯学生公寓1",
        lat: -31.95001,
        lng: 115.86455,
        name: "Campus Perth",
        googleMapLink: "https://goo.gl/maps/Jtvgn5su4MdY6JRZ8",
        chineseName: "",
        color: "yellow",
        expire: undefined,
      },
      {
        youtubeLink: "https://www.youtube.com/shorts/4-hinEE-_Ec",
        youtubeTitle: "周中很多Hotel都有特价套餐，大家有知道的分享在评论区呗😜",
        description: "牛排之夜",
        lat: -31.94741,
        lng: 115.82466,
        name: "Subiaco Hotel",
        googleMapLink: "https://goo.gl/maps/SQ4GVBTdCuM4VWyKA",
        chineseName: "",
        color: "red",
        expire: undefined,
      },
      {
        youtubeLink: "https://www.youtube.com/shorts/q6sz71-87P0",
        youtubeTitle: "嘻嘻",
        description: "珀斯游戏厅",
        lat: -31.79628,
        lng: 115.74801,
        name: "iPlay Whitfords",
        googleMapLink: "https://goo.gl/maps/QedpZzQLeEpKTRHx5",
        chineseName: "",
        color: "green",
        expire: undefined,
      },
      {
        youtubeLink: "https://www.youtube.com/watch?v=PQ5HCXNbuIU",
        youtubeTitle: "好多东西 眼花缭乱 #珀斯韩国超市",
        description: "逛珀斯韩国超市",
        lat: -32.04854,
        lng: 115.88033,
        name: "KAESUNG Mart",
        googleMapLink: "https://goo.gl/maps/cZNKk2FmkCwF6oZQ7",
        chineseName: "",
        color: "blue",
        expire: undefined,
      },
      {
        youtubeLink: "https://www.youtube.com/watch?v=4FnrEfpsYBs",
        youtubeTitle:
          "有空去逛逛 #珀斯集市 #珀斯周日 #周日集市 #水果蔬菜 #小吃摊",
        description: "珀斯周日集市",
        lat: -32.07197,
        lng: 115.89561,
        name: "Canning Vale Markets",
        googleMapLink: "https://goo.gl/maps/sk9MxHe4XKyN3kFw7",
        chineseName: "",
        color: "blue",
      },
      {
        lng: 115.75322,
        lat: -32.07236,
        name: "Run Amuk",
        chineseName: "",
        description: "珀斯第一的热狗",
        googleMapLink: "https://goo.gl/maps/LKXcHFQLqm1jwWN1A",
        youtubeLink: "https://www.youtube.com/watch?v=McDxb2cPnfg",
        youtubeTitle: "雀石泰酷辣 #珀斯热狗 #美式热狗 #热狗 #珀斯 #澳大利亚",
        color: "red",
      },

      {
        lng: 115.85784,
        lat: -31.94826,
        name: "Fortune Five Chinese Restaurant",
        chineseName: "",
        description: "珀斯早茶",
        googleMapLink: "https://goo.gl/maps/woqdJuf2J66TRvvw5",
        youtubeLink: "https://www.youtube.com/shorts/73cRCxk3Suw",
        youtubeTitle:
          "这家早茶竟然要排队半小时！ #珀斯美食 #珀斯早茶 #北桥美食 #广式早茶",
        color: "red",
      },
      {
        lng: 115.86243,
        lat: -31.95367,
        name: "Hifumiya Udon Noodle House",
        chineseName: "",
        description: "珀斯最火的午餐乌冬面",
        googleMapLink: "https://goo.gl/maps/T1WjT4fZne77Xtaf9",
        youtubeLink: "https://www.youtube.com/shorts/hVWUeC4_e3w",
        youtubeTitle:
          "便宜也不便宜 好吃也没很好吃 #why #珀斯美食 #珀斯乌冬 #珀斯日料",
        color: "red",
      },
      {
        lng: 115.85706,
        lat: -31.94896,
        name: "City Garden Chinese Restaurant",
        chineseName: "",
        description: "珀斯唐人街探店",
        googleMapLink: "https://goo.gl/maps/GMyCv6NCU8ytsLD69",
        youtubeLink: "https://www.youtube.com/shorts/6qckSNr_g6o",
        youtubeTitle: "又是吃爽的一天 #珀斯美食 #珀斯粤菜 #珀斯唐人街",
        color: "red",
      },
      {
        lng: 115.85894,
        lat: -31.95094,
        name: "Hiss & Smoke",
        chineseName: "",
        description: "珀斯日式烤串",
        googleMapLink: "https://goo.gl/maps/M1vScSz1oyyr66u46",
        youtubeLink: "https://www.youtube.com/shorts/zDyC0kv8xCU",
        youtubeTitle: "不错 除了贵了点儿 #珀斯美食 #珀斯日料 #日式烤串",
        color: "red",
      },
      {
        lng: 115.80873,
        lat: -31.8449,
        name: "Taste Canton Chinese Restaurant 食德",
        chineseName: "",
        description: "珀斯小众但巨好吃的粤菜",
        googleMapLink: "https://goo.gl/maps/QdKKxsxnKuzfpTSp7",
        youtubeLink: "https://www.youtube.com/shorts/jzsdb6nK6yY",
        youtubeTitle:
          "惊艳到我了 便宜还好吃 推荐给大家 #珀斯美食 #珀斯粤菜 #珀斯羊腩煲 #珀斯白切鸡 #珀斯石锅炒饭",
        color: "red",
      },
      {
        lng: 116.06059,
        lat: -32.12602,
        name: "Seven Sins Perth Hills",
        chineseName: "",
        description: "珀斯高分德国餐厅",
        googleMapLink: "https://goo.gl/maps/X9qRj7FzGkGcpSHk9",
        youtubeLink: "https://www.youtube.com/shorts/2a4wL89CwJI",
        youtubeTitle:
          "一开始没有猪肘我都快气炸啦，但是每个菜都很好吃，真是太开心了 #珀斯美食 #珀斯德国菜 #珀斯战俘牛排 #sevensins",
        color: "red",
      },
      {
        lng: 115.85706,
        lat: -31.94707,
        name: "Louder Louder",
        chineseName: "",
        description: "珀斯超好吃的泰餐",
        googleMapLink: "https://goo.gl/maps/DZip7xiKELkndJ1c6",
        youtubeLink: "https://www.youtube.com/shorts/CiCeAAuRWkU",
        youtubeTitle:
          "关注我，跟着我吃准没错✌️ #珀斯美食 #珀斯泰餐 #北桥美食 #泰餐 #LouderLouder",
        color: "red",
      },
      {
        lng: 115.86067,
        lat: -31.94675,
        name: "Sup So Good",
        chineseName: "",
        description: "珀斯超好吃的越南汤锅",
        googleMapLink: "https://goo.gl/maps/esNCspTDnQV4FBqq5",
        youtubeLink: "https://www.youtube.com/shorts/p5H_W_WTVp0",
        youtubeTitle:
          "三个人都吃不完 汤底还是Pho味 😋 #珀斯越南汤锅 #珀斯美食 #SupSoGood #北桥美食 #越南美食",
        color: "red",
      },
      {
        lng: 115.86143,
        lat: -31.95254,
        name: "Taka",
        chineseName: "",
        description: "珀斯最便宜日式快餐",
        googleMapLink: "https://goo.gl/maps/9NVLEjUJHEdLH8F56",
        youtubeLink: "https://www.youtube.com/shorts/hcd8ZruSzyk",
        youtubeTitle:
          "真的便宜好吃也用心 #珀斯最便宜的日式快餐 #珀斯日料 #珀斯美食 #TAKA",
        color: "red",
      },
      {
        lng: 115.78107,
        lat: -31.87702,
        name: "Little Bites",
        chineseName: "",
        description: "超好吃的红烧牛肉便当",
        googleMapLink: "https://goo.gl/maps/cyCiqtd3WG4zEwDR7",
        youtubeLink: "https://www.youtube.com/shorts/ZjcUKB__ssY",
        youtubeTitle:
          "用心做好料 #珀斯美食 #珀斯快餐 #珀斯梅菜扣肉 #珀斯川式炒面 #珀斯红烧牛肉便当",
        color: "red",
      },
      {
        lng: 115.94326,
        lat: -32.01582,
        name: "Bzbee Hotpot",
        chineseName: "壹食一锅",
        description: "珀斯最好吃的干锅肥肠",
        googleMapLink: "https://goo.gl/maps/jfn3aUGCJLgYp52p8",
        youtubeLink: "https://www.youtube.com/shorts/tIaBNGTZJ9s",
        youtubeTitle:
          "真的太好吃了 我还要再去 #珀斯中餐 #珀斯美食 #珀斯焖锅 #珀斯干锅 #珀斯川菜",
        color: "red",
      },
    ],
  };
};
