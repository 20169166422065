import * as React from "react";
import { useState, useEffect } from "react";
import Map, {
  Marker,
  Popup,
  NavigationControl,
  GeolocateControl,
} from "react-map-gl";
import { MarkerData } from "./DataSource";
import YouTube from "react-youtube";
import {
  Checkbox,
  FormGroup,
  FormControlLabel,
  TextField,
  Button,
} from "@mui/material";
import * as Sentry from "@sentry/react";
import Swal from "sweetalert2";

//Shift + Option + F

function App() {
  document.title = "珀斯王浩在澳洲-美食地图";

  const { data: originalData, initConfig } = MarkerData();

  const [popup, setPopup] = useState(undefined);

  const [showMenu, setShowMenu] = useState(initConfig.showMenu);

  const [red, setRed] = useState(initConfig.red);
  const [gray, setGray] = useState(initConfig.gray);
  const [blue, setBlue] = useState(initConfig.blue);
  const [yellow, setYellow] = useState(initConfig.yellow);
  const [purple, setPurple] = useState(initConfig.purple);
  const [green, setGreen] = useState(initConfig.green);
  const [searchValue, setSearchValue] = useState("");

  const [showMap, setShowMap] = useState(true);
  const [showMarkers, setShowMarkers] = useState(true);

  useEffect(() => {
    Sentry.captureEvent({
      message: "Web Opened",
      level: "info", // You can adjust the severity level
      extra: {},
    });
  }, []);

  let dark = initConfig.dark;

  if (
    window.matchMedia &&
    window.matchMedia("(prefers-color-scheme: dark)").matches
  ) {
    dark = true;
  } else {
    dark = false;
  }

  window
    .matchMedia("(prefers-color-scheme: dark)")
    .addEventListener("change", (event) => {
      const newColorScheme = event.matches ? "dark" : "light";
      if (newColorScheme === "dark") {
        dark = true;
      } else {
        dark = false;
      }
    });

  const array = originalData
    .filter((element) => (red ? true : element.color !== "red"))
    .filter((element) =>
      gray ? true : !(element?.expire && element?.expire < Date.now())
    )
    .filter((element) => (blue ? true : element.color !== "blue"))
    .filter((element) => (yellow ? true : element.color !== "yellow"))
    .filter((element) => (purple ? true : element.color !== "purple"))
    .filter((element) => (green ? true : element.color !== "green"))
    .filter((element) =>
      searchValue
        ? element.name
            .toLocaleLowerCase()
            .startsWith(searchValue.toLocaleLowerCase())
        : true
    );

  useEffect(() => {
    setShowMarkers(true);
  }, [array]);

  useEffect(() => {
    setShowMap(true);
  }, [dark]);

  if (!showMap) {
    return <></>;
  }

  return (
    <>
      <div
        style={{
          zIndex: 2,
          position: "absolute",
        }}
      >
        <FormGroup
          style={{
            backgroundColor: "#FFFFFF",
            borderRadius: "3px",
            border: "2px solid #e5e5e5",
            textAlign: "center",
          }}
        >
          {array.length}
        </FormGroup>
        <FormGroup
          style={{
            backgroundColor: "#FFFFFF",
            marginTop: "14px",
            borderRadius: "3px",
            border: "2px solid #e5e5e5",
          }}
        >
          <Button
            size="small"
            variant="contained"
            onClick={() => {
              Sentry.captureEvent({
                message: "Menu Button Clicked",
                level: "info", // You can adjust the severity level
                extra: {
                  // Include any additional context
                },
              });
              setShowMenu((prev) => !prev);
            }}
          >
            {showMenu ? "隐藏" : "筛选"}
          </Button>
        </FormGroup>

        {showMenu && (
          <>
            <FormGroup
              style={{
                backgroundColor: "#FFFFFF",
                paddingLeft: "14px",
                marginTop: "14px",
                borderRadius: "3px",
                border: "2px solid #e5e5e5",
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    size="small"
                    checked={red}
                    onChange={(event) => {
                      Sentry.captureEvent({
                        message: "Red Change Clicked",
                        level: "info", // You can adjust the severity level
                        extra: {
                          element: event.target.checked, // Include any additional context
                        },
                      });
                      setShowMarkers(false);
                      setRed(event.target.checked);
                    }}
                  />
                }
                label="餐厅 饮品"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    size="small"
                    checked={green}
                    onChange={(event) => {
                      Sentry.captureEvent({
                        message: "Green Change Clicked",
                        level: "info", // You can adjust the severity level
                        extra: {
                          element: event.target.checked, // Include any additional context
                        },
                      });
                      setShowMarkers(false);
                      setGreen(event.target.checked);
                    }}
                  />
                }
                label="景点 活动"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    size="small"
                    checked={blue}
                    onChange={(event) => {
                      Sentry.captureEvent({
                        message: "Blue Change Clicked",
                        level: "info", // You can adjust the severity level
                        extra: {
                          element: event.target.checked, // Include any additional context
                        },
                      });
                      setShowMarkers(false);
                      setBlue(event.target.checked);
                    }}
                  />
                }
                label="商家 超市"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    size="small"
                    checked={purple}
                    onChange={(event) => {
                      Sentry.captureEvent({
                        message: "Purple Change Clicked",
                        level: "info", // You can adjust the severity level
                        extra: {
                          element: event.target.checked, // Include any additional context
                        },
                      });
                      setShowMarkers(false);
                      setPurple(event.target.checked);
                    }}
                  />
                }
                label="酒吧 酒店"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    size="small"
                    checked={yellow}
                    onChange={(event) => {
                      Sentry.captureEvent({
                        message: "Yellow Change Clicked",
                        level: "info", // You can adjust the severity level
                        extra: {
                          element: event.target.checked, // Include any additional context
                        },
                      });
                      setShowMarkers(false);
                      setYellow(event.target.checked);
                    }}
                  />
                }
                label="住宅 信息"
              />
            </FormGroup>
            <FormGroup
              style={{
                backgroundColor: "#FFFFFF",
                paddingLeft: "14px",
                marginTop: "14px",
                borderRadius: "3px",
                border: "2px solid #e5e5e5",
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    size="small"
                    checked={gray}
                    onChange={(event) => {
                      Sentry.captureEvent({
                        message: "Gray Change Clicked",
                        level: "info", // You can adjust the severity level
                        extra: {
                          element: event.target.checked, // Include any additional context
                        },
                      });
                      setShowMarkers(false);
                      setGray(event.target.checked);
                    }}
                  />
                }
                label="过期"
              />
            </FormGroup>
            <FormGroup
              style={{
                backgroundColor: "#FFFFFF",

                marginTop: "14px",
                borderRadius: "3px",
                border: "2px solid #e5e5e5",
                width: "94px",
              }}
            >
              <TextField
                size="small"
                id="outlined-basic"
                label="店名搜索"
                variant="outlined"
                onChange={(event) => {
                  Sentry.captureEvent({
                    message: "Search Input Clicked",
                    level: "info", // You can adjust the severity level
                    extra: {
                      element: event.target.value, // Include any additional context
                    },
                  });
                  setShowMarkers(false);
                  setSearchValue(event.target.value);
                }}
              />
            </FormGroup>
          </>
        )}

        <FormGroup
          style={{
            backgroundColor: "#FFFFFF",
            marginTop: "14px",
            borderRadius: "3px",
            border: "2px solid #e5e5e5",
          }}
        >
          <Button
            size="small"
            variant="contained"
            onClick={() => {
              Sentry.captureEvent({
                message: "Activity Button Clicked",
                level: "info", // You can adjust the severity level
                extra: {
                  buttonId: "your-button-id", // Include any additional context
                },
              });
              Swal.fire({
                icon: "Success",
                title: "王浩发现的大活动：👍 免费得六个月的Uber One会员。",
                showConfirmButton: false,
                text: "eats-lihengz3ue",
                //text: "🆓 仅需将PayPal设为您的付款方式，即可获得为期6个月的免费Uber One会员资格。🤑 原价每月$9.99。🚚 免送货费 🎁 会员专属活动。",
                confirmButtonText: "关闭",
                footer:
                  '<div><a href="http://ubr.to/EatsGiveGet" target="_blank" rel="noopener noreferrer">🪜 第一步：如果您尚无Uber账户，请先点击此处创建新账户，然后在Promotions页面输入折扣码eats-lihengz3ue，即可获得新人$10优惠。</a><br /><br /><a href="https://www.ubereats.com/au/partnerships?code=groupCode~paypal_au&offering-id=5a87159c-9a89-4b6c-839e-4fee64faea58&pass-campaign=PARTNER_PAYPAL_AU&access-point=PARTNER_WEB_HOME&screen-id=offering-redemption" target="_blank" rel="noopener noreferrer" >👩‍💻 第二步：如果您已经拥有账户，请点击这里访问Uber One活动页面，领取六个月免费会员资格。</a><br /></div>',
              });
            }}
          >
            Uber活动
          </Button>
        </FormGroup>

        <FormGroup
          style={{
            backgroundColor: "#FFFFFF",
            marginTop: "14px",
            borderRadius: "3px",
            border: "2px solid #e5e5e5",
          }}
        >
          <Button
            size="small"
            variant="contained"
            onClick={() => {
              Sentry.captureEvent({
                message: "DiDi Button Clicked",
                level: "info", // You can adjust the severity level
                extra: {
                  buttonId: "your-button-id", // Include any additional context
                },
              });
              Swal.fire({
                icon: "Success",
                title: "👍 DiDi新用户送$20。",
                showConfirmButton: false,
                //text: "eats-lihengz3ue",
                //text: "🆓 仅需将PayPal设为您的付款方式，即可获得为期6个月的免费Uber One会员资格。🤑 原价每月$9.99。🚚 免送货费 🎁 会员专属活动。",
                confirmButtonText: "关闭",
                footer:
                  '<div><a href="https://d.didiglobal.com/redeem?code=Liheng11" target="_blank" rel="noopener noreferrer">🪜 点击此处创建新账户，即可获得新人$20乘车优惠。</a><br/></div>',
              });
            }}
          >
            DiDi活动
          </Button>
        </FormGroup>
      </div>
      {
        <Map
          mapLib={import("mapbox-gl")}
          initialViewState={{
            longitude: 115.85996,
            latitude: -31.9511,
            zoom: 13,
          }}
          style={{ width: "100dvw", height: "100dvh", zIndex: 1 }}
          mapStyle={
            dark
              ? "mapbox://styles/mapbox/dark-v11"
              : "mapbox://styles/mapbox/streets-v12"
          }
        >
          <GeolocateControl
            positionOptions={{ enableHighAccuracy: true }}
            trackUserLocation={true}
            showAccuracyCircle={true}
            showUserLocation={true}
            showUserHeading={true}
            position="top-right"
          />
          <NavigationControl position="top-right" />

          {showMarkers &&
            array.map((el, idx) => {
              return (
                <Marker
                  key={idx}
                  longitude={el.lng}
                  latitude={el.lat}
                  color={
                    el?.expire && el?.expire < Date.now() ? "gray" : el.color
                  }
                  anchor="center"
                  onClick={() => {
                    Sentry.captureEvent({
                      message: "Marker Button Clicked",
                      level: "info", // You can adjust the severity level
                      extra: {
                        el, // Include any additional context
                      },
                    });
                    setPopup(el);
                  }}
                />
              );
            })}

          {popup && popup.lat && popup.lng && (
            <Popup
              latitude={popup.lat}
              longitude={popup.lng}
              onClose={() => setPopup(undefined)}
              closeButton={true}
              anchor="bottom"
              closeOnClick={false}
              maxWidth={"280px"}
            >
              <div>
                <h3>✔️ {popup.description}</h3>

                {popup?.googleMapEmbedLink ? (
                  <h4>📍 {popup.name}</h4>
                ) : (
                  <>
                    📍{" "}
                    <a
                      href={popup.googleMapLink}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {popup.name}
                    </a>
                    <br />
                  </>
                )}
                <h4>🖥️ {popup.youtubeTitle}</h4>
                <YouTube
                  videoId={popup.youtubeLink.slice(-11)}
                  opts={{ width: "260" }}
                />
                {popup?.googleMapEmbedLink && (
                  <>
                    <iframe
                      title={popup.googleMapEmbedLink}
                      src={popup.googleMapEmbedLink}
                      width="256"
                      allowfullscreen="true"
                      loading="lazy"
                      referrerpolicy="no-referrer-when-downgrade"
                    ></iframe>
                    <br />
                  </>
                )}

                {popup?.activityLink && (
                  <>
                    🧑‍💻{" "}
                    <a
                      href={popup.activityLink}
                      target="_blank"
                      rel="noreferrer"
                    >
                      官网
                    </a>
                    <br />
                  </>
                )}
                {popup?.attention && (
                  <>
                    <h4>‼️ {`${popup?.attention}`}</h4>
                  </>
                )}
                {popup?.expire &&
                  (popup?.expire < Date.now() ? (
                    <>
                      <h4>✖️ 已过期</h4>
                    </>
                  ) : (
                    <>
                      <h4>
                        ✌️ 过期时间：{new Date(popup?.expire).toLocaleString()}
                      </h4>
                    </>
                  ))}
              </div>
            </Popup>
          )}
        </Map>
      }
    </>
  );
}

export default App;
